// src/Login.js

import React, { useState } from 'react';
import { ethers } from 'ethers';
import {
  TextField,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  Alert,
} from '@mui/material';

function Login({ handleLogin }) {
  const [privateKey, setPrivateKey] = useState('');
  const [error, setError] = useState('');

  const handleLoginClick = () => {
    try {
      const wallet = new ethers.Wallet(privateKey.trim());
      handleLogin(wallet);
    } catch (error) {
      setError('Invalid private key. Please check and try again.');
    }
  };

  return (
    <Box maxWidth={500} mx="auto" mt={8}>
      <Card>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Login to Your Wallet
          </Typography>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <TextField
            label="Private Key"
            variant="outlined"
            fullWidth
            value={privateKey}
            onChange={(e) => setPrivateKey(e.target.value)}
            margin="normal"
            type="password"
            helperText="Ensure you keep your private key secure. Do not share it with anyone."
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleLoginClick}
            fullWidth
            sx={{ mt: 2 }}
          >
            Login
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
}

export default Login;
