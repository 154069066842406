// src/contexts/PortfolioContext.js

import React, { createContext, useState, useEffect } from 'react';

// Vytvoření kontextu
export const PortfolioContext = createContext();

// Poskytovatel kontextu
export const PortfolioProvider = ({ children }) => {
  const [portfolio, setPortfolio] = useState({});

  // Načtení portfolia z localStorage při načtení aplikace
  useEffect(() => {
    const savedPortfolio = localStorage.getItem('portfolio');
    if (savedPortfolio) {
      setPortfolio(JSON.parse(savedPortfolio));
    }
  }, []);

  // Uložení portfolia do localStorage při změně
  useEffect(() => {
    localStorage.setItem('portfolio', JSON.stringify(portfolio));
  }, [portfolio]);

  // Přidání kryptoměny do portfolia
  const addToPortfolio = (coin, amount) => {
    setPortfolio((prev) => ({
      ...prev,
      [coin.id]: {
        ...coin,
        amount: parseFloat(amount),
      },
    }));
  };

  // Odebrání kryptoměny z portfolia
  const removeFromPortfolio = (coinId) => {
    setPortfolio((prev) => {
      const updated = { ...prev };
      delete updated[coinId];
      return updated;
    });
  };

  // Aktualizace množství kryptoměny v portfoliu
  const updatePortfolioAmount = (coinId, amount) => {
    setPortfolio((prev) => ({
      ...prev,
      [coinId]: {
        ...prev[coinId],
        amount: parseFloat(amount),
      },
    }));
  };

  return (
    <PortfolioContext.Provider
      value={{
        portfolio,
        addToPortfolio,
        removeFromPortfolio,
        updatePortfolioAmount,
      }}
    >
      {children}
    </PortfolioContext.Provider>
  );
};
