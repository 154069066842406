// src/contexts/ThemeContext.js

import React, { createContext, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { lightTheme, darkTheme } from './Theme';

// Vytvoření Theme Context
export const ThemeContext = createContext();

// Poskytovatel kontextu
export function ThemeProviderContext({ children }) {
  const [darkMode, setDarkMode] = useState(false);

  // Funkce pro přepínání tmavého režimu
  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  // Výběr tématu na základě stavu darkMode
  const theme = darkMode ? darkTheme : lightTheme;

  return (
    <ThemeContext.Provider value={{ darkMode, toggleDarkMode }}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}
