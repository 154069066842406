// src/App.js

import React, { useState, useEffect, useContext } from 'react';
import { ethers } from 'ethers';
import {
  CssBaseline,
  Box,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  Paper,
  InputBase,
  Switch,
  Tooltip,
} from '@mui/material';
import {
  Menu as MenuIcon,
  Dashboard as DashboardIcon,
  ShowChart as MarketIcon,
  SwapHoriz as SwapIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';

// Import dalších komponent
import Login from './Login';
import Wallet from './Wallet';
import Transactions from './Transactions';
import CreateWallet from './CreateWallet';
import Notifications from './Notifications';
import Settings from './Settings';
import Market from './Market';
import Swap from './Swap';
import Deposit from './Deposit';
import Withdraw from './Withdraw';

// Import kontextů
import { ThemeContext } from './ThemeContext';
import { NotificationsProvider } from './NotificationsContext';
import { PortfolioProvider } from './PortfolioContext';

const drawerWidth = 240;

function App() {
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  const [wallet, setWallet] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [currentView, setCurrentView] = useState('dashboard');

  useEffect(() => {
    const savedPrivateKey = localStorage.getItem('privateKey');
    if (savedPrivateKey) {
      try {
        const savedWallet = new ethers.Wallet(savedPrivateKey);
        setWallet(savedWallet);
      } catch (error) {
        console.error('Chyba při načítání soukromého klíče:', error);
        localStorage.removeItem('privateKey');
      }
    }
  }, []);

  const handleLogin = (wallet) => {
    localStorage.setItem('privateKey', wallet.privateKey);
    setWallet(wallet);
  };

  const handleLogout = () => {
    localStorage.removeItem('privateKey');
    setWallet(null);
    setCurrentView('dashboard');
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuClick = (view) => {
    setCurrentView(view);
    setMobileOpen(false);
  };

  const drawer = (
    <div>
      <Toolbar>
        <Typography variant="h6" noWrap component="div" sx={{ color: 'primary.main' }}>
          CRYPTO DASHBOARD
        </Typography>
      </Toolbar>
      <Divider />
      <List>
        {[
          { text: 'Dashboard', icon: <DashboardIcon />, view: 'dashboard' },
          { text: 'Market', icon: <MarketIcon />, view: 'market' },
          { text: 'Swap', icon: <SwapIcon />, view: 'swap' },
          { text: 'Wallet', icon: <AccountBalanceWalletIcon />, view: 'wallet' },
          { text: 'Transactions', icon: <AccountBalanceWalletIcon />, view: 'transactions' },
          { text: 'Settings', icon: <SettingsIcon />, view: 'settings' },
        ].map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton
              selected={currentView === item.view}
              onClick={() => handleMenuClick(item.view)}
            >
              <ListItemIcon
                sx={{ color: currentView === item.view ? 'primary.main' : 'inherit' }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <Box sx={{ position: 'absolute', bottom: 0, width: '100%' }}>
        <Divider />
        <List>
          <ListItem>
            <ListItemIcon>
              {darkMode ? <Switch checked onChange={toggleDarkMode} /> : <Switch onChange={toggleDarkMode} />}
            </ListItemIcon>
            <ListItemText primary="Dark Mode" />
          </ListItem>
          {wallet && (
            <ListItem button onClick={handleLogout}>
              <ListItemIcon>
                <AccountBalanceWalletIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          )}
        </List>
      </Box>
    </div>
  );

  return (
    <NotificationsProvider>
      <PortfolioProvider>
        <CssBaseline />
        <Box sx={{ display: 'flex' }}>
          <AppBar
            position="fixed"
            sx={{
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              ml: { sm: `${drawerWidth}px` },
              bgcolor: 'background.paper',
              color: 'text.primary',
              boxShadow: 1,
            }}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                {currentView.charAt(0).toUpperCase() + currentView.slice(1)}
              </Typography>
              <Paper
                component="form"
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  width: 300,
                  mr: 2,
                  bgcolor: 'background.default',
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search..."
                  inputProps={{ 'aria-label': 'search' }}
                />
                <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                  <SearchIcon />
                </IconButton>
              </Paper>
              <Tooltip title="User Profile">
                <IconButton color="inherit">
                  <Avatar alt="User" src="/static/images/avatar/1.jpg" />
                </IconButton>
              </Tooltip>
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}
            >
              {drawer}
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: 'none', sm: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}
              open
            >
              {drawer}
            </Drawer>
          </Box>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              mt: 8,
            }}
          >
            {!wallet ? (
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Login handleLogin={handleLogin} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CreateWallet handleLogin={handleLogin} />
                </Grid>
              </Grid>
            ) : currentView === 'dashboard' ? (
              <Wallet wallet={wallet} />
            ) : currentView === 'market' ? (
              <Market />
            ) : currentView === 'swap' ? (
              <Swap wallet={wallet} />
            ) : currentView === 'transactions' ? (
              <Transactions wallet={wallet} />
            ) : currentView === 'settings' ? (
              <Settings />
            ) : (
              <Typography variant="h6">Content for {currentView} view</Typography>
            )}
          </Box>
        </Box>
      </PortfolioProvider>
    </NotificationsProvider>
  );
}

export default App;
