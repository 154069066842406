// src/index.js

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ThemeProviderContext } from './contexts/ThemeContext';
import { Web3ReactProvider } from '@web3-react/core';
import { ethers } from 'ethers'; // Správný import
import { NotificationsProvider } from './contexts/NotificationsContext';
import { PortfolioProvider } from './contexts/PortfolioContext';

// Funkce pro získání knihovny Ethers.js z poskytovatele
function getLibrary(provider) {
  try {
    const library = new ethers.providers.Web3Provider(provider); // Správné použití
    library.pollingInterval = 12000; // 12 sekund
    return library;
  } catch (error) {
    console.error('Error initializing Ethers.js library:', error);
    return null;
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <ThemeProviderContext>
        <NotificationsProvider>
          <PortfolioProvider>
            <App />
          </PortfolioProvider>
        </NotificationsProvider>
      </ThemeProviderContext>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
