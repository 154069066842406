// src/contexts/NotificationsContext.js

import React, { createContext, useState, useContext } from 'react';

// Create the Notifications Context
const NotificationsContext = createContext();

// Create a Provider Component
export function NotificationsProvider({ children }) {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  // Function to add a new notification
  const addNotification = (notification) => {
    setNotifications((prevNotifications) => [notification, ...prevNotifications]);
    setUnreadCount((prevCount) => prevCount + 1);
  };

  // Function to mark all notifications as read
  const markAllAsRead = () => {
    setUnreadCount(0);
  };

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        addNotification,
        unreadCount,
        markAllAsRead,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
}

// Custom hook for easy access to the Notifications Context
export function useNotifications() {
  return useContext(NotificationsContext);
}
