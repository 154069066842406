// src/CreateWallet.js

import React, { useState } from 'react';
import { ethers } from 'ethers';
import {
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';

function CreateWallet({ handleLogin }) {
  const [open, setOpen] = useState(false);
  const [newWallet, setNewWallet] = useState(null);

  const handleCreate = () => {
    const randomWallet = ethers.Wallet.createRandom();
    setNewWallet(randomWallet);
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
    handleLogin(newWallet);
  };

  return (
    <Box maxWidth={500} mx="auto" mt={8}>
      <Card>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Create a New Wallet
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Generate a new Ethereum wallet. Make sure to back up your private key securely.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCreate}
            fullWidth
            sx={{ mt: 2 }}
          >
            Create Wallet
          </Button>
        </CardContent>
      </Card>

      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle>Your New Wallet</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Here is your private key. Please back it up securely. This key is the only way to access your wallet.
          </DialogContentText>
          {newWallet && (
            <Alert severity="warning" sx={{ mt: 2, wordBreak: 'break-all' }}>
              {newWallet.privateKey}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            I've Saved It
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default CreateWallet;
